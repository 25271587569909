//nav bar
.navbar-container {
  box-shadow: 0px 8px 16px 0px #00000026;
  padding: 12px 24px 12px 24px;
}

.button-contact-us {
  padding: 8px 16px 8px 16px;
  border-radius: 50px;
  gap: 8px;

}

//hero section

.button-hire-us {
  padding: 16px 8px 16px 8px;
  border-radius: 50px;
  gap: 8px;
}

.our-details-container {
  padding: 24px;
  border-radius: 10px;
  background-color: #FFFFFF;
 box-shadow: 0px 4px 14px 0px #0B3D911A;
}

//our works

.button-see-all {
  padding: 16px 24px 16px 24px;
  border-radius: 70px;
  gap: 8px;
  border: 2px solid #0B3D91;
}

.graphic-design-background {
  background-image: url("../../../../public/images/landing/our-works-graphic-design-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

//happy clients

.btn-left-right-arrow {
  border: 1px solid #0B3D91;
  border-radius: 100px;
  padding: 8px;
  gap: 8px;
}

.button-container {
  gap: 32px;
}

//contact us

.button-submit {
  padding: 12px 8px 12px 8px;
  border-radius: 50px;
  gap: 8px;

}

::placeholder {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8D8D8D;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

//background colors
.bg-interface-color {
  background-color: #F4F4F4;
}

.bg-indigo {
  background-color: #0B3D91;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}


//border-radius
.br-4 {
  border-radius: 4px;
}

.br-50 {
  border-radius: 50px !important;
}

.br-8 {
  border-radius: 8px;
}

.br-78 {
  border-radius: 78px;
}

.br-10 {
  border-radius: 10px;
}

.border-none {
  border: none
}

.border-indigo {
  border-color: #0B3D91;
}

.border-grey-1px {
  border: 1px solid rgba(201, 201, 201, 0.50);
}

//.border-grey3-1px {
//  border: 1px solid rgba(201, 201, 201, 0.50);
//}

.border-grey-3 {
  border: 1px solid #D9D9D9;
}

//box-shadow
.box-shadow {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);;
}


//fonts
.font-lato {
  font-family: 'Lato', sans-serif;
}

.font-georgia {
  font-family: Georgia !important;
}


//font-sizes
.font-size-72 {
  font-size: 72px;
}

.font-size-20 {
  font-size: 20px;
}

@include media-breakpoint-down(sm) {
  .font-size-20 {
    font-size: 16px;
  }
}

.font-size-24 {
  font-size: 24px;
}

@include media-breakpoint-down(sm) {
  .font-size-24 {
    font-size: 16px;
  }
}

.font-size-48 {
  font-size: 48px;
}

@include media-breakpoint-down(sm) {
  .font-size-48 {
    font-size: 24px;
  }
}

.font-size-56 {
  font-size: 56px;
}

@include media-breakpoint-down(sm) {
  .font-size-56 {
    font-size: 28px;
  }
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

//font-weight
.font-weight-700 {
  font-weight: 700;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

//text-colors

.text-color-primary {
  color: #0B3D91;
}

.text-indgo {
  color: #0B3D91 !important;
}

.text-light-yellow {
  color: #FBC108;
}

.text-color-light-grey {
  color: #787878;
}

.text-color-grey-1 {
  color: #8D8D8D;
}

.text-color-grey-dark {
  color: #464646
}

.text-color-black {
  color: #1C1C1C;
}


//padding
.pl-50 {
  padding-left: 50px;
}

.px-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

//margin
.ml-20 {
  margin-left: 20px;
}

.m-36 {
  margin: 36px;
}


//height
.h-400 {
  height: 400px;
}

.h-377 {
  height: 377px;
}

.h-564 {
  height: 564px;
}

.h-222 {
  height: 222px;
}

.h-32 {
  height: 32px;
}

.h-5 {
  height: 5px;
}

.h-2 {
  height: 2px;
}

.hw-80 {
  height: 80px;
  width: 80px;
}

.hw-24 {
  height: 24px;
  width: 24px;
}

//width
.w-500 {
  width: 500px;
}

.w-548 {
  width: 548px;
}

.w-262 {
  width: 262px;
}

.w-32 {
  width: 32px;
}

.w-357 {
  width: 357px;
}

.w-508 {
  width: 508px;
}

.w-330 {
  width: 300px;
}

//border


.display-none {
  display: none;
}

.display-block {
  display: block;
}


@include media-breakpoint-down(sm){
  .font-size-28 {
    font-size: 28px;
  }

  .res-text-align-center {
    text-align: center;
  }

  .res-display-none {
    display: none !important;
  }

  .res-display-block {
    display: block !important;
  }
  .res-mt-5 {
    margin-top: 5px;
  }

  .res-flex-column {
    flex-direction: column;
  }

  .res-flex-column-reverse {
    flex-direction: column-reverse;
  }

  .res-width-100p {
    width: 100% !important;
  }
  .res-font-size-24 {
    font-size: 24px;
  }
  .res-font-size-32 {
    font-size: 32px;
  }
  .res-font-size-16 {
    font-size: 16px;
  }
  .res-border-left-black {
    border-left: 2px solid black;
  }


  .w-18 {
    width: 18px;
  }

  .h-18 {
    height: 18px;
  }

  .h-300 {
    height: 300px;
  }
  .h-400 {
    height: 400px;
  }

  .res-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .res-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .res-pl-50 {
    padding-left: 50px;
  }
  .res-ml-20 {
    margin-left: 20px;
  }

  .res-justify-content-center {
    justify-content: center;
  }
}

.text-align-center {
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 48px;
  font-weight: 700;
}

.left-lower-side-text {
  position: absolute;
  top: 70%;
  left: 38%;
  transform: translate(-100%, 50%);
}

.left-lower-side-below-text {
  position: absolute;
  top: 78%;
  left: 47%;
  transform: translate(-100%, 40%);
}

.text-below-centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 80%);
  font-size: 20px;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}


//animations and transitions
.overlay-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.overlay-container:hover .overlay {
  z-index: 1;
  opacity: 1;
}


.bg-images-linear-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.bg-blog-tag-name {
  background: #0B3D911A;
}


.beebloom-image {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.10) 66.33%, rgba(0, 0, 0, 0.90) 100%), lightgray 50%;
}

.beebloom-text {
  transition: opacity 0.3s ease-in-out;
}

//team
.team-member-img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .team-member-img {
    height: 400px; /* Mobile height */
  }
}

//happy client
.custom-card {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: revert;
  align-self: stretch;
  padding: 24px; /* Add padding to the bottom */
  height: 100%; /* Ensure all boxes have the same height */
  border: none;
}


::placeholder {
  color: #8D8D8D;
}


.works-beebloom-preview-image:hover {
  .display-content {
    display: block !important;
  }
}

.fitness-tracker-preview-image:hover {
  .display-content {
    display: block !important;
  }
}


.works-fuel-desk-preview-image:hover {
  .display-content {
    display: block !important;
  }
}

.works-event-replay-preview-image:hover {
  .display-content {
    display: block !important;
  }
}

.hydromate-preview-image:hover {
  .display-content {
    display: block !important;
  }
}

.works-dating-preview-image:hover {
  .display-content {
    display: block !important;
  }
}


.success-msg-display-container {
  background: #408140;
  border-radius: 10px;
  border: none;
}

.error-msg-display-container {
  background: #FF4444;
  border-radius: 10px;
  border: none;
}


.bg-about-us-container {
  background-image: url("../../../../public/images/About/about-us-mission-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.web-site-background {
  background-image: url("../../../../public/images/landing/web-app-development-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.web-app-background {
  background-image: url("../../../../public/images/landing/website-app-design-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.web-app-content-background {
  background-image: url("../../../../public/images/landing/content-writing-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Initially hide the spinner */
#loading-spinner {
  display: none;
}

.iti {
  width: 100%;
}

//.iti {
//  --iti-path-flags-1x: url('intl-tel-input/build/img/flags.webp');
//  --iti-path-flags-2x: url('intl-tel-input/build/img/flags@2x.webp');
//  --iti-path-globe-1x: url('intl-tel-input/build/img/globe.webp');
//  --iti-path-globe-2x: url('intl-tel-input/build/img/globe@2x.webp');
//}
//
//.iti_flag {
//  background-image: var(--iti-path-flags-1x) !important;
//}

@include media-breakpoint-up(md) {
  .blog-details-container {
    height: 100vh;
    overflow-y: scroll;
  }

  .blog-details-container::-webkit-scrollbar {
    visibility: hidden;
  }

  .blog-table-content {
    position: sticky; /* Sticky position */
    top: 0; /* Stick to the top of the container */
    flex-shrink: 0;
    height: 100vh;
    overflow-y: scroll;
  }

  .blog-table-content::-webkit-scrollbar {
    visibility: hidden;
  }
}

.custom-phone-input .PhoneInputInput {
  border: none; /* Remove the border */
  outline: none; /* Remove the default focus outline */
  box-shadow: none; /* Remove any default box-shadow */
}

.tag-search-bar {
  outline: none !important;
  box-shadow: none !important;
}

@include media-breakpoint-up(md) {
  .navbar-nav button {
    font-size: 20px;
  }
}

.technologies-list {
  list-style-type: none;
}
